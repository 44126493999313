import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID, colorPalette } from '@/server-data'

export function createEmptyUnifiedVehicle(
  name: string,
  timeWindow: uui.domain.rm.TimeWindow,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
): uui.domain.client.UnifiedVehicle {
  return {
    allowGps: false,
    isPhysicalDevice: false,
    hasRoutingLicense: true,
    vehicle: {
      color: colorPalette[0],
      gpsDeviceId:
        trackingProvider === 'telematics' ? TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID : undefined,
      externalId: name,
      id: '',
      idx: -1,
      edgeOverride: 'REL_NOT_SELECTED',
      tracked: false,
      lockedDates: [],
      routingProfile: 'default',
      enableSsosr: false,
      settings: {
        any: {
          available: true,
          activationCost: 0,
          additionalServiceTimePerc: 0,
          additionalServiceTimeSec: 0,
          avoidDepotAdditionalServiceTime: false,
          arrivalDepotId: null,
          breakTimeCost: 2000,
          drivingTimeCost: 2000,
          idleTimeCost: 2000,
          serviceTimeCost: 2000,
          perStopCost: 0,
          kmCost: 100,
          speedFactor: 100,
          tags: [],
          notes: '',
          timeWindow,
          earlyDeparture: false,
          maxWorkingTimeSec: 0,
          maxDistanceMt: 0,
          minWorkingTimeSec: 0,
          breaks: [],
          departureDepotId: null,
          loadCapacities: {},
          maxDrivingTimeSec: 0,
          maxOrders: 0,
          minLoadCapacities: {},
          perStopTimeSec: 0,
          regionIds: [],
          preparationTimeSec: 0,
          closeOutTimeSec: 0,
        },
      },
    },
    vehicleId: '',
    driversByRecurrence: {},
    unifiedId: '',
    tags: [],
  }
}
