import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'

import { Tooltip } from '@/components/primitives/Tooltip'
import {
  selectRemainingTelematicsLicenses,
  selectTelematicsDeviceTransientStatuses,
  selectTelematicsSources,
  selectTelematicsTenantSources,
  selectTelematicsTenantSourcesAsArray,
} from '@/features/domain/account'
import { selectDeviceIdsByTenantSource, selectTelematicsDevices } from '@/features/domain/device'
import {
  getOpsDataFiltered,
  selectTerritories,
  selectTerritoryId,
} from '@/features/domain/territory'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { DropDownField, type DropdownItem } from '@/forms-legacy'
import { naturalSort } from '@/server-data'
import { store } from '@/store'

import { useTexts } from '../../../hooks/useTexts'

import { useOrganizeComponents } from './hooks/useOrganizeComponents'

import { TelematicsAlert } from './components/TelematicsAlert'
import { TrackingHeader } from './components/TrackingHeader'
import { TrackingSwitch } from './components/TrackingSwitch'

interface Props {
  initialValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
  values: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
}

function getUpdatedDeviceOptions(
  tenantSourceId: string,
  deviceIdsByTenantSource: Record<string, string[]>,
  devices: Record<string, uui.domain.client.gps.telematics.DeviceInfo>,
  vehicles: Record<string, uui.domain.client.UnifiedVehicle>,
  territories: Record<string, uui.domain.client.rm.Territory>,
  vehiclesByVehicleId: Record<string, string>,
  currentTerritoryId: string,
  deviceTransientStatuses: Record<
    string,
    {
      status: 'PENDING-ACTIVATION' | 'PENDING-DEACTIVATION' | 'ERROR'
      vehicleId: string
      message: string
    }
  >,
  vehiclesByTerritory?: uui.domain.server.rm.TerritoriesDriversAndVehicles,
): DropdownItem[] {
  const ids = deviceIdsByTenantSource[tenantSourceId] ?? []

  return ids
    .map(id => {
      const device = devices[id]

      const transientStatus = deviceTransientStatuses[id]
      const vehicleId = transientStatus?.vehicleId ?? device.correlationHandle ?? ''
      const vehicle = vehicleId === '' ? undefined : vehicles[vehiclesByVehicleId[vehicleId]]

      // This prevents situation when a device is in a pending activation state
      const deviceWithPendingActivationStatus = transientStatus?.status === 'PENDING-ACTIVATION'
      const deviceWithPendingDectivationStatus = transientStatus?.status === 'PENDING-DEACTIVATION'

      const territoryId = deviceWithPendingActivationStatus
        ? currentTerritoryId
        : device.territoryId
          ? device.territoryId
          : undefined
      const territory = territoryId ? territories[territoryId] : undefined

      // This prevents situation when a devices got a correlationHandle but the vehicle has been updated and got no (or another) gps device
      const stillAssignedToVehicle =
        !vehicle || (vehicle?.hasRoutingLicense && vehicle.vehicle.gpsDeviceId === device.deviceId)

      // Device assigned in another territory
      const deviceAssignedInAnotherTerritory =
        device.active && currentTerritoryId !== device.territoryId

      const vehicleLabel = territoryId
        ? vehiclesByTerritory?.[territoryId]?.vehicles[vehicleId]
        : undefined
      const territoryLabel = territory ? territory.name : undefined

      const info =
        !deviceWithPendingDectivationStatus && vehicleLabel && territoryLabel
          ? `${vehicleLabel} - ${territoryLabel}`
          : undefined

      const disabled =
        deviceAssignedInAnotherTerritory ||
        (device.active && stillAssignedToVehicle) ||
        deviceWithPendingActivationStatus

      return {
        id: device.deviceId,
        info,
        label: device.deviceLabel ?? device.deviceId,
        disabled,
      }
    })
    .sort((a, b) => naturalSort(a.label, b.label))
}

async function fetchVehiclesByTerritory(
  setVehiclesByTerritory: (
    vehiclesByTerritory: uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined,
  ) => void,
) {
  const result = await store.dispatch(getOpsDataFiltered())

  if (getOpsDataFiltered.rejected.match(result)) {
    throw new Error('Failed to fetch vehicles by territory')
  }

  setVehiclesByTerritory(result.payload)
}

export function TelematicsTracking(props: Props) {
  const { values, initialValues } = props

  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const deviceIdsByTenantSource = useSelector(selectDeviceIdsByTenantSource)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)

  const tenantSourcesArray = useSelector(selectTelematicsTenantSourcesAsArray)
  const remainingLicenses = useSelector(selectRemainingTelematicsLicenses)
  const tenantSources = useSelector(selectTelematicsTenantSources)
  const territories = useSelector(selectTerritories)
  const territoryId = useSelector(selectTerritoryId)

  const vehicles = useSelector(selectVehicles)
  const devices = useSelector(selectTelematicsDevices)
  const sources = useSelector(selectTelematicsSources)
  const texts = useTexts()
  const form = useForm()

  const [vehiclesByTerritory, setVehiclesByTerritory] = useState<
    uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined
  >()

  const { alertParts, trackByFieldVisible } = useOrganizeComponents()

  useEffect(() => {
    fetchVehiclesByTerritory(setVehiclesByTerritory)
  }, [devices, setVehiclesByTerritory])

  const [deviceOptions, setDeviceOptions] = useState<DropdownItem[]>(
    values.telematics.tenantSourceId
      ? [
          {
            id: '',
            label: texts.selectGpsDevice,
            disabled: true,
          },
          ...getUpdatedDeviceOptions(
            values.telematics.tenantSourceId,
            deviceIdsByTenantSource,
            devices,
            vehicles,
            territories,
            vehiclesByVehicleId,
            territoryId,
            deviceTransientStatuses,
            vehiclesByTerritory,
          ),
        ]
      : [],
  )

  const tenantSourceOptions = useMemo(() => {
    values.telematics.tenantSourceId
    const tenantSourcesOptions = tenantSourcesArray
      .reduce<DropdownItem[]>((acc, ts) => {
        const isAssignedToPhysicalDevice =
          values.telematics.tenantSourceId !== '' &&
          tenantSources[values.telematics.tenantSourceId].sourceLabel !== 'WWGpsProcessor'
        if (
          remainingLicenses <= 0 &&
          ts.sourceLabel !== 'WWGpsProcessor' &&
          !isAssignedToPhysicalDevice
        )
          return acc

        const source = sources[ts.sourceId]

        if (ts.sourceLabel === 'WWGpsProcessor') {
          acc.push({
            id: ts.id,
            label: texts.mobileTenantSourceLabel,
            disabled: !ts.active,
          })
          return acc
        }

        const info = !ts.active
          ? texts.deleting
          : source.label === ts.label
            ? undefined
            : source.label

        acc.push({
          id: ts.id,
          info,
          label: ts.label,
          disabled: !ts.active,
        })

        return acc
      }, [])
      .sort((a, b) => naturalSort(a.label, b.label))

    if (tenantSourcesOptions.length === 1) {
      return tenantSourcesOptions
    }

    const selectIntegrationOption = {
      id: '',
      label: texts.selectAnIntegration,
      disabled: true,
    }

    return [selectIntegrationOption, ...tenantSourcesOptions]
  }, [sources, tenantSourcesArray, texts])

  useEffect(() => {
    const tenantSource = tenantSources[values.telematics.tenantSourceId]

    setDeviceOptions(
      values.telematics.tenantSourceId && tenantSource.sourceLabel !== 'WWGpsProcessor'
        ? [
            {
              id: '',
              label: texts.selectGpsDevice,
              disabled: true,
            },
            ...getUpdatedDeviceOptions(
              values.telematics.tenantSourceId,
              deviceIdsByTenantSource,
              devices,
              vehicles,
              territories,
              vehiclesByVehicleId,
              territoryId,
              deviceTransientStatuses,
              vehiclesByTerritory,
            ),
          ]
        : [],
    )
  }, [
    deviceIdsByTenantSource,
    deviceTransientStatuses,
    vehiclesByVehicleId,
    vehiclesByTerritory,
    setDeviceOptions,
    tenantSources,
    territoryId,
    territories,
    vehicles,
    devices,
    values,
    texts,
  ])

  useEffect(() => {
    if (remainingLicenses > 0 || initialValues.rm.deviceId) return
    form.change('telematics.gpsTracking', false)
  }, [form, initialValues, remainingLicenses])

  const noTenantSources = tenantSourcesArray.length === 0
  const showTrackingDetails =
    values.telematics.gpsTracking && !noTenantSources && trackByFieldVisible

  const singleIntegrationInDeletion =
    values.telematics.gpsTracking &&
    tenantSourcesArray.length === 1 &&
    !tenantSourcesArray[0].active

  const tenantSource = tenantSources[values.telematics.tenantSourceId]
  const currentIntegrationInDeletion = tenantSource && !tenantSource.active

  const onlyOneIntegration = tenantSourceOptions.length === 1
  const tenantSourceTooltipTitle =
    onlyOneIntegration && tenantSourcesArray.length === 1
      ? texts.onlyMobileTenantSourceAvailable
      : texts.onlyMobileTenantSourceAvailableNoLicenses

  return (
    <>
      <TrackingHeader>
        <TrackingSwitch disabled={noTenantSources} />
      </TrackingHeader>

      {showTrackingDetails && (
        <>
          <Tooltip
            placement="bottom"
            disabled={!onlyOneIntegration}
            title={tenantSourceTooltipTitle}
            containerStyle={{ width: '100%' }}
          >
            <DropDownField
              testid="telematics.tenantSourceId"
              values={tenantSourceOptions}
              label={texts.integrations}
              name="telematics.tenantSourceId"
              wrappedInputProps={{
                rowClassName: 'o-dropdown__row--double-label',
                disabled: onlyOneIntegration,
                editable: onlyOneIntegration ? false : undefined,
                disableListOpenWhenDisabled: true,
              }}
              disableRestoreButton
            />
          </Tooltip>

          {currentIntegrationInDeletion && (
            <Typography color="error" fontSize={13} marginY={1}>
              {texts.integrationDeletionInProgressChooseAnother}
            </Typography>
          )}
        </>
      )}

      <TelematicsAlert alertParts={alertParts} />

      {singleIntegrationInDeletion && (
        <Typography color="primary" fontSize={13} marginY={1}>
          {texts.integrationDeletionInProgress(tenantSourcesArray[0].label)}
        </Typography>
      )}

      {deviceOptions.length > 0 && (
        <DropDownField
          testid="rm.deviceId"
          values={deviceOptions}
          label={texts.gpsDevice}
          name="rm.deviceId"
          wrappedInputProps={{
            disabled: singleIntegrationInDeletion || currentIntegrationInDeletion,
            rowClassName: 'o-dropdown__row--double-label',
            footerSecondaryText: ` • ${texts.remainingLicenses(remainingLicenses)}`,
          }}
        />
      )}
    </>
  )
}
